import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UTypingTypographyComponent } from './u-typing-typography.component';
import { UiInfraModule } from '../../ui-infra.module';

@NgModule({
  declarations: [UTypingTypographyComponent],
  imports: [CommonModule, UiInfraModule],
  exports: [UTypingTypographyComponent],
})
export class UTypingTypographyModule {}
